import { errAsync, okAsync, ResultAsync } from 'neverthrow';
import firestore, { Tables } from '../firebase/firestore';
import stripe from '../stripe/stripe';
import functions from '../firebase/functions';
import authService from '../firebase/auth';
import { Account } from "@/../../lib";
import store from '@/store';

// Services
import loggingService from './logging.service';

class AccountService {
    get = (): ResultAsync<Account, string> => {
        const accountId = authService.getUid();
        if (!accountId) return errAsync('No account ID found');
        const result = firestore.getDocument<Account>(accountId, Tables.accounts);
        result.mapErr((error: any) => loggingService.error(error));
        return result.andThen((account: Account) => {
            if (!account) {
                return errAsync(`get: Account not found for id: ${accountId}`);
            }
            return okAsync(account);
        });
    };

    delete = (): ResultAsync<void, string> => {
        const uid = authService.getUid();
        const onDeleteAccount = functions.httpsCallable('onDeleteAccount');
        authService.deleteCurrentUser();
        return ResultAsync.fromPromise(
            onDeleteAccount({ accountId: uid }),
            (error) => `onDeleteAccount failed with: ${(error as Error).message}`
        );
    }

    signUp = (email: string, password: string): ResultAsync<string, string> => {
        return authService.signUp(email, password);
    }

    signIn = (email: string, password: string): ResultAsync<string, string> => {
        return authService.signIn(email, password);
    }

    createStripeAccount = async (vendor: any): Promise<ResultAsync<string, string>> => {
        const uid = authService.getUid();
        if (!uid) return errAsync('createStripeAccount: No account ID found');
        const email = authService.getEmail();
        if (!email) return errAsync('createStripeAccount: No email found');
        const result = await stripe.createStripeAccount(
            vendor,
            uid,
            email
        );
        if (result.isOk()) {
            // @ts-ignore
            return okAsync(result.value.data.stripeAccountUrl);
        } else {
            return errAsync(result.error);
        }
    }

    getStripeAccountId = (): string | null => {
        return store.getters['dashboard/stripeAccountId'];
    }

    getStripeAccountUrl = async (): Promise<ResultAsync<string, string>> => {
        const stripeAccountid = this.getStripeAccountId()
        if (!stripeAccountid) return errAsync('getStripeAccountUrl: No stripe account id found');
        const result = await stripe.createNewAccountUrl(stripeAccountid);
        if (result.isOk()) {
            // @ts-ignore
            return okAsync(result.value.data.stripeAccountUrl);
        } else {
            return errAsync(result.error);
        }
    }

}

export default new AccountService();
