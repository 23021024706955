import { Table, Record } from "@/../../lib";

interface State {
    currentTable: Table | null;
    homeTables: Table[] | null;
    exampleRecords: Record[];
}

export const state: State = {
    currentTable: null,
    homeTables: null,
    exampleRecords: []
};

export default state;
export { State };
