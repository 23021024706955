import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { ResultAsync, errAsync, okAsync } from 'neverthrow';


class FirebaseAuth {
    isLoggedIn = (ignoreAnonymous: boolean): boolean => {
        const { currentUser } = firebase.auth();
        if (currentUser) {
            if (ignoreAnonymous && currentUser.isAnonymous) {
                return false;
            }
            return true;
        }
        return false;
    };

    signInAnonymously = (): ResultAsync<string, string> => {
        const userCredentialResult = ResultAsync.fromPromise(
            firebase.auth().signInAnonymously(),
            (error) => (error as Error).message
        );

        return userCredentialResult.andThen((userCredential) => {
            const { user } = userCredential;
            if (user == null) {
                return errAsync('Firebase user is null after anonymous signup');
            }

            return okAsync(user.uid);
        });
    };

    signUp = (email: string, password: string): ResultAsync<string, string> => {
        const userCredentialResult = ResultAsync.fromPromise(
            firebase.auth().createUserWithEmailAndPassword(email, password),
            (error) => (error as Error).message
        );
        return userCredentialResult.andThen((userCredential) => {
            const { user } = userCredential;
            if (user == null) {
                return errAsync('Firebase user is null after signup');
            }
            user.sendEmailVerification();
            return okAsync(user.uid);
        });
    };

    signIn = (email: string, password: string): ResultAsync<string, string> => {
        const userCredentialResult = ResultAsync.fromPromise(
            firebase.auth().signInWithEmailAndPassword(email, password),
            (error) => (error as Error).message
        );

        return userCredentialResult.andThen((userCredential) => {
            const { user } = userCredential;
            if (user == null) {
                return errAsync('Firebase user is null after email sign in');
            }
            return okAsync(user.uid);
        });
    };

    isAnonymous = (): boolean => {
        const { currentUser } = firebase.auth();
        if (currentUser && currentUser.isAnonymous) {
            return true;
        }
        return false;
    };

    signOut = async () => firebase.auth().signOut();

    deleteCurrentUser = () => {
        const { currentUser } = firebase.auth();

        if (currentUser) {
            return ResultAsync.fromPromise(currentUser.delete(), (error) => errAsync(error));
        }
        return okAsync(undefined);
    };

    getUid = (): string | null => {
        const { currentUser } = firebase.auth();
        if (currentUser) {
            return currentUser.uid;
        }
        return null;
    }

    getEmail = (): string | null => {
        const { currentUser } = firebase.auth();
        if (currentUser) {
            return currentUser.email;
        }
        return null;
    }
}

export default new FirebaseAuth();
