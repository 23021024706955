// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions'

class FirebaseFunctions {
    private f: firebase.functions.Functions;

    constructor() {
        this.f = firebase.app().functions();
    }

    httpsCallable = (args: string): any => this.f.httpsCallable(args);
}

export default new FirebaseFunctions();
