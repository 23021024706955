import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=4ba21368&scoped=true&"
var script = {}
import style0 from "./Tag.vue?vue&type=style&index=0&id=4ba21368&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba21368",
  null
  
)

export default component.exports