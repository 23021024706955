import { ResultAsync, errAsync, okAsync, ok, err, Result } from 'neverthrow';
import firestore, { Tables } from '../firebase/firestore';
import functions from '../firebase/functions';

// Models
import { Table, Record, TableStatus } from '@/../../lib';

// Services
import loggingService from './logging.service';


class TableService {
    private downloadCSV = (url: string, name: string): void => {
        // console.log(url);
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = () => {
            const blob = xhr.response;
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", name.toLocaleLowerCase().replace(' ', '-') + ".csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        xhr.open("GET", url);
        xhr.send();
        xhr.onerror = () => {
            loggingService.error('Error downloading CSV');
            // open url in new tab
            window.open(url, '_blank')
        }
    }

    getTableByUrl = (tableUrl: string): ResultAsync<Table, string> => {
        const result: ResultAsync<Table, string> = firestore
            .queryDocumentsWhere<Table>(Tables.tables, [['url', '==', tableUrl]])
            .andThen((tables) => {
                if (tables.length === 0) {
                    return errAsync<Table, string>(`No table found with url ${tableUrl}`);
                }
                return okAsync(tables[0]);
            });
        result.mapErr((error: any) => loggingService.error(error));
        return result;
    };

    get = async (tableId: string): Promise<ResultAsync<Table, string>> => {
        return firestore.getDocument<Table>(tableId, Tables.tables);
    };

    getExampleRecords = async (tableId: string, exampleRecordsIds: string[]): Promise<Result<Record[], string>> => {
        // split exampleRecordsIds into chunks of 10 because the firebase 'in' operator only supports 10 ids at a time
        const exampleRecords: Record[] = [];
        for (let i = 0; i < exampleRecordsIds.length; i += 10) {
            const result = await firestore
                .queryDocumentsWhere<Record>(`${Tables.tables}/${tableId}/${Tables.records}`, [['id', 'in', exampleRecordsIds.slice(i, i + 10)]])
                .andThen((records) => {
                    return okAsync(records);
                });
            if (result.isErr()) {
                return err<Record[], string>(result.error);
            }
            exampleRecords.push(...result.value);
        }
        return ok(exampleRecords);
    }

    /**
     * @param state
     * @returns {Promise<ResultAsync<string, string>>}
     * @memberof TableService
     * @description Get tables for the marketplace.
     * We use state to filter the tables because in firestore rules 
     * is a rule that prohibits to fetch tables with 
     * state == "PAID" and ownerStripeAccountId == "". Therefore, we 
     * canot fetch them at ones because the rules dont work like filters
     */
    getMarketplaceTables = (state: TableStatus): ResultAsync<Table[], string> => {
        let result: ResultAsync<Table[], string> = errAsync<Table[], string>('No tables found');
        if (state === TableStatus.PAID) {
            result = firestore
                .queryDocumentsWhere<Table>(Tables.tables, [
                    ['ownerStripeAccountId', '!=', null],
                    ['status', '==', TableStatus.PAID],
                ])
                .andThen((tables) => {
                    return okAsync(tables);
                });
        } else {
            result = firestore
                .queryDocumentsWhere<Table>(Tables.tables, [
                    ['status', 'in', [TableStatus.FREE, TableStatus.PUBLIC]]
                ])
                .andThen((tables) => {
                    return okAsync(tables);
                });
        }
        result.mapErr((error: any) => loggingService.error(error));
        return result;
    };

    downloadFreeCSV = async (table: Table, datapoints: number): Promise<ResultAsync<string, string>> => {
        const onDownloadFreeCSV = functions.httpsCallable('onDownloadFreeCSV');
        const result = await onDownloadFreeCSV({ tableId: table.id, tableHeaderConfig: table.tableHeader, datapoints: datapoints });
        this.downloadCSV(result.data.url, table.title);
        return okAsync('success');
    }

    downloadPaidCSV = async (table: Table, orderId: string): Promise<ResultAsync<string, string>> => {
        const result = await functions.httpsCallable('onDownloadPaidCSV')({ orderId: orderId });
        this.downloadCSV(result.data.url, table.title);
        return okAsync('success');
    }

    getPrice = async (table: Table, datapoints: number): Promise<ResultAsync<number, string>> => {
        const onGetTablePrice = functions.httpsCallable('onGetTablePrice');
        return ResultAsync.fromPromise(
            onGetTablePrice({ minPrice: table.minPrice, minAmount: table.minOrderAmount, tableHeader: table.tableHeader, datapoints: datapoints }),
            (error) => `onGetTablePrice failed with: ${(error as Error).message}`
        );
    }

    sendDownloadDoesNotWorkMsg = async (orderId: string): Promise<ResultAsync<string, string>> => {
        const result = await functions.httpsCallable('onSendDownloadDoesNotWorkMsg')({ orderId: orderId });
        return okAsync(result.data);
    }
}

export default new TableService();
