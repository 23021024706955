import { render, staticRenderFns } from "./PulsatingDot.vue?vue&type=template&id=622891a7&scoped=true&"
var script = {}
import style0 from "./PulsatingDot.vue?vue&type=style&index=0&id=622891a7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622891a7",
  null
  
)

export default component.exports