import { ResultAsync } from 'neverthrow';
import functions from '../firebase/functions';

class Stripe {
    createStripeAccount = async (vendor: any, accountId: string, email: string): Promise<ResultAsync<string, string>> => {
        const onCreateStripeAccount = functions.httpsCallable('onCreateStripeAccount');
        return ResultAsync.fromPromise(
            onCreateStripeAccount({ vendor, accountId, email }),
            (error) => `onCreateStripeAccount failed with: ${(error as Error).message}`
        );
    }

    createNewAccountUrl = async (stripeAccountId: string): Promise<ResultAsync<string, string>> => {
        const onCreateNewAccountUrl = functions.httpsCallable('onCreateNewAccountUrl');
        return ResultAsync.fromPromise(
            onCreateNewAccountUrl({ stripeAccountId }),
            (error) => `onCreateNewAccountUrl failed with: ${(error as Error).message}`
        );
    }
}

export default new Stripe();
