
import firebase from './firebase/firebase';
import store from '@/store';
import tableService from './services/table.service';
import accountService from './services/account.service';
import router from '@/router';
import { Account, TableStatus } from "@/../../lib";

class TableStock {
    constructor() {
        // Firebase auth
        firebase.onAuthStateChanged(async (user) => {
            store.commit('SET_IS_LOGGED_IN', false);
            if (user) {
                if (!user.isAnonymous) {
                    store.commit('SET_IS_LOGGED_IN', true);
                    const result = await accountService.get();
                    if (result.isOk()) {
                        const account = result.value as Account;
                        store.commit('dashboard/SET_ACCOUNT', account);
                    }
                }
            }
        });

        // Load tables for site home
        if (router.currentRoute.path === '/') {
            // Load tables seperately depending on their status. This is for security.
            // We load them seperately so we can make the calls asyncronously.
            tableService.getMarketplaceTables(TableStatus.FREE).then((result) => {
                if (result.isOk()) {
                    if (!store.getters['marketplace/homeTables']) {
                        store.dispatch('marketplace/setHomeTables', result.value);
                    } else {
                        const currentTables = store.getters['marketplace/homeTables']
                        store.dispatch("marketplace/setHomeTables", currentTables.concat(result.value));
                    }
                }
            });
            tableService.getMarketplaceTables(TableStatus.PAID).then((result) => {
                if (result.isOk()) {
                    if (!store.getters['marketplace/homeTables']) {
                        store.dispatch('marketplace/setHomeTables', result.value);
                    } else {
                        const currentTables = store.getters['marketplace/homeTables']
                        store.dispatch("marketplace/setHomeTables", currentTables.concat(result.value));
                    }
                }
            });
        }
    }

    onAuthStateChanged = (callback: () => any) => {
        firebase.onAuthStateChanged(callback);
    };
}

export default new TableStock();
