import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import tablestock from './tablestock/tablestock';
import vueCountryRegionSelect from 'vue-country-region-select'

// @ts-ignore-next-line
import vuetify from './plugins/vuetify';

// Awesome components
import '@/components/awsm/registry';

Vue.config.productionTip = false
Vue.use(vueCountryRegionSelect)

tablestock.onAuthStateChanged(() => {
    new Vue({
        store,
        router,
        vuetify,
        render: (h) => h(App),
    }).$mount('#app');
});
