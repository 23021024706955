import { PageState } from '@../../../lib';
import { RootState } from './root.state';

export const SET_IS_MOBILE = (state: RootState, isMobile: boolean): void => {
    state.isMobile = isMobile;
};

export const SET_LAST_PATH = (state: RootState, lastPath: string): void => {
    state.lastPath = lastPath;
}

export const SET_IS_LOGGED_IN = (state: RootState, isLoggedIn: boolean): void => {
    state.isLoggedIn = isLoggedIn;
}

export const SET_PAGE_STATE = (state: RootState, pageState: PageState): void => {
    state.pageState = pageState;
}
