import Vue from 'vue';

// Import global components
import NavBar from './navigation/NavBar.vue';
import NavRoute from './navigation/NavRoute.vue';
import PageProgress from './PageProgress.vue';
import NavAvatar from './navigation/NavAvatar.vue';
import Progress from './Progress.vue';
import TableSummaryCard from './TableSummaryCard.vue';
import Button from './Button.vue';
import TableStockLogo from './TableStockLogo.vue';
import TagCloud from './TagCloud.vue';
import Tag from './Tag.vue';
import UpdateTicker from './UpdateTicker.vue';
import PulsatingDot from './PulsatingDot.vue';
import TextInput from './TextInput.vue';
import PopoverInfo from './PopoverInfo.vue';
import Checkbox from './Checkbox.vue';
import Avatar from './Avatar.vue';
import CountrySelect from './CountrySelect.vue';
import OnboardingStatusButton from './OnboardingStatusButton.vue';
import LogoutButton from './LogoutButton.vue';
import DeleteAccountButton from './DeleteAccountButton.vue';
import Modal from './Modal.vue';
import StateBlock from './order_status/StateBlock.vue';
import TextButton from './TextButton.vue';
import ParseSeconds from './ParseSeconds.vue';
import ButtonSection from './order_status/ButtonSection.vue';
import CookieBanner from './CookieBanner.vue';
import ContactForm from './ContactForm.vue';
import TextArea from './TextArea.vue';
import SearchResultItem from './organisms/SearchResultItem.vue';

// Home
import Header from './home/Header.vue';
import Footer from './home/Footer.vue';

// Table
import TableLayout from './table/Layout.vue';
import TableCTA1 from './table/CTA1.vue';
import TableCTA2 from './table/CTA2.vue';
import TableDatapointField from './table/DatapointField.vue';
import TableTable from './table/Table.vue';
import TableCell from './table/Cell.vue';
import PayButtonAndDialog from './table/PayButtonAndDialog.vue';

// Register global components
Vue.component('awsm-navbar', NavBar);
Vue.component('awsm-nav-route', NavRoute);
Vue.component('awsm-page-progress', PageProgress);
Vue.component('awsm-nav-avatar', NavAvatar);
Vue.component('awsm-progress', Progress);
Vue.component('awsm-table-summary-card', TableSummaryCard);
Vue.component('awsm-button', Button);
Vue.component('awsm-table-stock-logo', TableStockLogo);
Vue.component('awsm-table-layout', TableLayout);
Vue.component('awsm-table-CTA1', TableCTA1);
Vue.component('awsm-table-CTA2', TableCTA2);
Vue.component('awsm-table-datapoint-field', TableDatapointField);
Vue.component('awsm-tag-cloud', TagCloud);
Vue.component('awsm-tag', Tag);
Vue.component('awsm-update-ticker', UpdateTicker);
Vue.component('awsm-pulsating-dot', PulsatingDot);
Vue.component('awsm-text-input', TextInput);
Vue.component('awsm-table-table', TableTable);
Vue.component('awsm-table-cell', TableCell);
Vue.component('awsm-popover-info', PopoverInfo);
Vue.component('awsm-checkbox', Checkbox);
Vue.component('awsm-home-header', Header);
Vue.component('awsm-home-footer', Footer);
Vue.component('awsm-avatar', Avatar);
Vue.component('awsm-country-select', CountrySelect);
Vue.component('awsm-onboarding-status-button', OnboardingStatusButton);
Vue.component('awsm-logout-button', LogoutButton);
Vue.component('awsm-delete-account-button', DeleteAccountButton);
Vue.component('awsm-pay-button-and-dialog', PayButtonAndDialog);
Vue.component('awsm-modal', Modal);
Vue.component('awsm-state-block', StateBlock);
Vue.component('awsm-text-button', TextButton);
Vue.component('awsm-parse-seconds', ParseSeconds);
Vue.component('awsm-order-status-button-section', ButtonSection);
Vue.component('awsm-cookie-banner', CookieBanner);
Vue.component('awsm-contact-form', ContactForm);
Vue.component('awsm-text-area', TextArea);

Vue.component('awsm-o-search-result-item', SearchResultItem);

export default Vue;
