import { RootState } from './root.state';
import { ActionContext } from 'vuex';
import { PageState } from '@../../../lib';

export function setIsMobile(context: ActionContext<RootState, RootState>, isMobile: boolean): void {
    context.commit('SET_IS_MOBILE', isMobile);
}

export function setCurrentPath(context: ActionContext<RootState, RootState>, lastPath: string): void {
    context.commit('SET_LAST_PATH', lastPath);
}

export function setIsLoggedIn(context: ActionContext<RootState, RootState>, isLoggedIn: boolean): void {
    context.commit('SET_IS_LOGGED_IN', isLoggedIn);
}

export function setPageState(context: ActionContext<RootState, RootState>, pageState: PageState): void {
    context.commit('SET_PAGE_STATE', pageState);
}
