import { State } from "./state";
import { Table, Record } from "@/../../lib";
import { ActionContext } from "vuex";
import { RootState } from "@/store/root.state";


export function setCurrentTable(context: ActionContext<State, RootState>, currentTable: Table | null): void {
    context.commit('SET_CURRENT_TABLE', currentTable);
}

export function setHomeTables(context: ActionContext<State, RootState>, homeTables: Table[] | null): void {
    context.commit('SET_HOME_TABLES', homeTables);
}

export function setToggleTableCellSelected(context: ActionContext<State, RootState>, cellValue: string): void {
    context.commit('SET_TABLE_CELL_SELECTED', cellValue);
}

export function setExampleRecords(context: ActionContext<State, RootState>, exampleRecords: Record[] | null): void {
    context.commit('SET_EXAMPLE_RECORDS', exampleRecords);
}
