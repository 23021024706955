export * from "./types";

// How to import this project into firebase functions:
// https://stackoverflow.com/questions/58632138/using-a-shared-node-module-for-common-classes/58877253#58877253

// In my case this ment to add these lines to firebase.json functions/predeploy:
// "shx rm -rf \"$RESOURCE_DIR/src/lib\"",
// "shx cp -R \"$PROJECT_DIR/../lib\" \"$RESOURCE_DIR/src/lib\"",

// Additionally, to build the types before running npm run build in functions project (copies everything form this project), add this to scripts in package.json:
// "build_types": "shx rm -rf \"$RESOURCE_DIR./src/lib\" && shx cp -R \"$PROJECT_DIR./../../lib\" \"$RESOURCE_DIR./src/lib\"",
// And then run npm run build_types

// To import types with "import { X } from "@/lib"" add this to tsconfig.json inside compilerOptions:
// "paths": {
//     "@/*": [
//       "./src/*"
//     ]
// },

// The same is true for the app engine project. I used the folder "types" instead of "lib":
// "build_types": "shx rm -rf \"$RESOURCE_DIR./src/types\" && shx cp -R \"$PROJECT_DIR./../../types\" \"$RESOURCE_DIR./src/lib\"",
// And then run npm run build_types
