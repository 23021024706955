import { PageState, PageStates } from "@/../../lib";

interface RootState {
    isMobile: boolean;
    isLoggedIn: boolean;
    lastPath: string;
    pageState: PageState;
}

export const state: RootState = {
    isMobile: false,
    isLoggedIn: false,
    lastPath: '/',
    pageState: { state: PageStates.READY, message: '' },
};

export default state;
export { RootState };
