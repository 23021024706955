import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { isInitialized } from '../tablestock/firebase/firebase';

// RootStore
import store from '@/store';
import tableService from '@/tablestock/services/table.service';
import { Table } from "@/../../lib";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/Marketplace/Wrapper.vue'),
    children: [
      {
        path: '',
        alias: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Marketplace/Home.vue'),
      },
      {
        path: 'table/:tableUrl',
        name: 'Table',
        component: () => import(/* webpackChunkName: "table" */ '../views/Marketplace/Table.vue'),
        props: (route) => ({ tableUrl: route.params.tableUrl })
      },
      {
        path: 'table/:tableUrl/t',
        name: 'TableOnly',
        component: () => import(/* webpackChunkName: "tableOnly" */ '../views/Marketplace/TableOnly.vue'),
        props: (route) => ({ tableUrl: route.params.tableUrl })
      }
    ]
  },
  {
    path: '/welcome/',
    component: () => import(/* webpackChunkName: "onboardingRight" */ '../views/Onboarding/Wrapper.vue'),
    children: [
      {
        path: 'register',
        name: 'ScreenRegister',
        components: {
          right: () => import(/* webpackChunkName: "screenRegister" */ '../views/Onboarding/ScreenRegister.vue'),
        },
      },
      {
        path: 'type',
        name: 'ScreenAccountType',
        components: {
          right: () => import(/* webpackChunkName: "screenAccountType" */ '../views/Onboarding/ScreenAccountType.vue'),
        },
      },
      {
        path: 'refresh',
        name: 'ScreenRefresh',
        components: {
          right: () => import(/* webpackChunkName: "screenRefresh" */ '../views/Onboarding/ScreenRefresh.vue'),
        },
      },
      {
        path: 'login',
        name: 'ScreenLogin',
        components: {
          right: () => import(/* webpackChunkName: "screenLogin" */ '../views/Onboarding/ScreenLogin.vue'),
        },
      },
    ]
  },
  {
    path: '/dashboard/',
    component: () => import(/* webpackChunkName: "onboardingRight" */ '../views/Dashboard/Wrapper.vue'),
    children: [
      {
        path: '',
        alias: '/',
        name: 'Overview',
        components: {
          main: () => import(/* webpackChunkName: "obscreen1" */ '../views/Dashboard/Overview.vue'),
        },
      }
    ]
  },
  {
    path: '/order/:orderId',
    component: () => import(/* webpackChunkName: "orderStatus" */ '../views/Marketplace/OrderStatus.vue'),
    props: (route) => ({
      orderId: route.params.orderId,
      // paymentIntent: route.query.payment_intent, <-- same as orderId
      paymentIntentClientSecret: route.query.payment_intent_client_secret,
      redirectStatus: route.query.redirect_status,
    })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

function nA(fromPath: string, next: (path: string) => void) {
  const { isLoggedIn } = store.getters;
  const toPath = isLoggedIn ? '/dashboard' : '/';
  if (toPath === fromPath) return;
  store.commit('SET_CURRENT_PATH', toPath);
  return next(toPath);
}

function loadExampleRecords(table: Table) {
  store.commit('marketplace/SET_EXAMPLE_RECORDS', []);
  tableService
    .getExampleRecords(table.id, table.exampleRecordIds)
    .then((result) => {
      if (result.isOk()) {
        store.commit('marketplace/SET_EXAMPLE_RECORDS', result.value);
      }
    });
}

async function loadTablePage(toPath: string) {
  if (!toPath.includes('/table/')) return;
  const tableUrl = toPath.split('/')[2];

  // If table is loaded do not load again
  const currentTable = store.getters['marketplace/currentTable'];
  if (currentTable) if (currentTable.url === tableUrl) return;

  // Check if table is already loaded in homeTables
  const homeTables = store.getters['marketplace/homeTables'];
  if (homeTables) {
    const table = homeTables.find((t: { url: string; }) => t.url === tableUrl);
    if (table) {
      store.commit('marketplace/SET_CURRENT_TABLE', table);
      loadExampleRecords(table);
      return;
    }
  }

  // Load table
  store.commit('SET_PAGE_STATE', { state: "LOADING", message: "Loading table..." });
  await isInitialized()
  store.commit('marketplace/SET_CURRENT_TABLE', null);
  store.commit('marketplace/SET_EXAMPLE_RECORDS', []);
  tableService.getTableByUrl(tableUrl).then((result) => {
    if (result.isOk()) {
      store.commit('marketplace/SET_CURRENT_TABLE', result.value);
      loadExampleRecords(result.value);
      store.commit('SET_PAGE_STATE', { state: "READY" });
    } else {
      store.commit('SET_PAGE_STATE', { state: "READY", message: result.error });
    }
  });
}

router.beforeEach((to, from, next) => {
  const { isLoggedIn } = store.getters;
  const toPath = to.path;
  const fromPath = from.path;
  const onboardingState = store.getters['dashboard/onboardingState'];
  // console.log(`Route: ${from.path} -> ${to.path}, isLoggedIn: ${isLoggedIn}, onboardingState: ${onboardingState}`);

  loadTablePage(toPath);

  if (toPath === '/welcome/login' && isLoggedIn) {
    return nA(fromPath, next);
  }
  if (toPath === '/welcome/register' && isLoggedIn) {
    return nA(fromPath, next);
  }
  if (toPath === '/welcome/type' && onboardingState !== 'ACC_CREATED' && fromPath !== '/welcome/register') {
    return nA(fromPath, next);
  }
  if (toPath === '/welcome/refresh' && onboardingState !== 'STRIPE_ACC_CREATED') {
    return nA(fromPath, next);
  }

  store.commit('SET_LAST_PATH', fromPath);
  next();
});

export default router
