import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { ResultAsync, okAsync } from 'neverthrow';

// Models
import { Table, Record, Account, Order } from "@/../../lib";

enum Tables {
    tables = 'tables',
    records = 'records',
    accounts = 'accounts',
    orders = 'orders'
}

type WhereFilterOp = firebase.firestore.WhereFilterOp;
type DocumentData = firebase.firestore.DocumentData;

type FirestoreItem = Record | Table | Account | Order;
type QueryCondition = { field: string; operation: WhereFilterOp; value: any };

type QueryResult<T extends FirestoreItem> = {
    items: T[];
    loadMore: (() => ResultAsync<QueryResult<T>, string>) | null;
};

class FirebaseFirestore {
    firestore: firebase.firestore.Firestore;

    constructor() {
        this.firestore = firebase.firestore();
    }

    queryDocumentsWhere = <T extends FirestoreItem>(
        table: Tables | string,
        where: [[string, WhereFilterOp, any], [string?, WhereFilterOp?, any?]?],
        orderBy?: { field: string; ascending: boolean } | null,
        limit?: number | null
    ): ResultAsync<T[], string> => {
        let query:
            | firebase.firestore.CollectionReference<DocumentData>
            | firebase.firestore.Query<DocumentData> = this.firestore.collection(table);

        query = query.where(where[0][0], where[0][1], where[0][2]);

        let q2 = '';
        if (where[1]) {
            if (where[1][0] && where[1][1] && where[1][2]) {
                q2 = `and ${(where[1][0], where[1][1], where[1][2])}`;
                query = query.where(where[1][0], where[1][1], where[1][2]);
            }
        }

        if (orderBy) {
            if (orderBy.ascending) {
                query = query.orderBy(orderBy.field, 'asc');
            } else {
                query = query.orderBy(orderBy.field, 'desc');
            }
        }

        if (limit) {
            query = query.limit(limit);
        }

        const querySanpshot = ResultAsync.fromPromise(
            query.get(),
            (error) =>
                `Querying document ${(where[0][0], where[0][1], where[0][2])
                } ${q2} in table ${table} failed with: ${(error as Error).message}`
        );

        return querySanpshot.andThen((qSnap) => {
            const results: T[] = [];
            qSnap.forEach((document) => {
                results.push(document.data() as T);
            });
            return okAsync(results);
        });
    };

    setDocument = (
        item: FirestoreItem,
        table: Tables,
        id?: string | null
    ): ResultAsync<string, string> => {
        let newDocument: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

        if (id) {
            newDocument = this.firestore.collection(table).doc(id);
        } else {
            newDocument = this.firestore.collection(table).doc();
        }

        // Necessary for comparing already loaded objects later.
        // eslint rule "no-param-reassign" turned off therefore
        if (!item.id) item.id = newDocument.id;

        const result = ResultAsync.fromPromise(
            newDocument.set(item),
            (error) => `Creating item in table ${table} failed with: ${(error as Error).message}`
        );
        return result.map(() => newDocument.id);
    };

    getDocument = <T extends FirestoreItem>(id: string, table: Tables): ResultAsync<T, string> => {
        const documentResult = ResultAsync.fromPromise(
            this.firestore.collection(table).doc(id).get(),
            (error) => `Reading document ${id} in table ${table} failed with: ${(error as Error).message}`
        );

        return documentResult.andThen((document) => okAsync(document.data() as T));
    };

    deleteDocument(id: string, table: Tables): ResultAsync<void, string> {
        return ResultAsync.fromPromise(
            this.firestore.collection(table).doc(id).delete(),
            (error) =>
                `Deleting document ${id} in table ${table} failed with: ${(error as Error).message}`
        );
    }

    listenToQueryWhere = <T extends FirestoreItem>(
        table: Tables,
        conditions: QueryCondition[],
        listener: (document: T[]) => any,
        onError: (error: Error) => any,
        orderBy?: { field: string; ascending: boolean }
    ): (() => void) => {
        let query:
            | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
            | firebase.firestore.Query<firebase.firestore.DocumentData> = this.firestore.collection(
                table
            );

        conditions.forEach((condition) => {
            query = query.where(condition.field, condition.operation, condition.value);
        });

        if (orderBy) {
            if (orderBy.ascending) {
                query = query.orderBy(orderBy.field, 'asc');
            } else {
                query = query.orderBy(orderBy.field, 'desc');
            }
        }

        return query.onSnapshot((querySnapshot) => {
            const results: T[] = [];
            querySnapshot.forEach((document) => {
                results.push(document.data() as T);
            });
            listener(results);
        }, onError);
    };

    queryDocumentsWithCursorWhere = <T extends FirestoreItem>(
        table: Tables,
        conditions: { field: string; operation: WhereFilterOp; value: any }[],
        orderBy: { field: string; ascending: boolean },
        limit: number,
        startAfter?: firebase.firestore.DocumentData
    ): ResultAsync<QueryResult<T>, string> => {
        let query:
            | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
            | firebase.firestore.Query<firebase.firestore.DocumentData> = this.firestore.collection(
                table
            );

        conditions.forEach((condition) => {
            query = query.where(condition.field, condition.operation, condition.value);
        });

        if (orderBy.ascending) {
            query = query.orderBy(orderBy.field, 'asc');
        } else {
            query = query.orderBy(orderBy.field, 'desc');
        }

        if (startAfter) {
            query = query.startAfter(startAfter);
        }

        query = query.limit(limit);

        const result = ResultAsync.fromPromise(
            query.get(),
            (error) => `Querying documents failed with: ${(error as Error).message}`
        ).andThen((querySnapshot) => {
            const documents: T[] = [];
            let lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
            querySnapshot.forEach((document) => {
                documents.push(document.data() as T);
                lastDoc = document;
            });
            let loadMore: (() => ResultAsync<QueryResult<T>, string>) | null = null;
            if (documents.length === limit) {
                loadMore = () =>
                    this.queryDocumentsWithCursorWhere<T>(table, conditions, orderBy, limit, lastDoc);
            }
            return okAsync({ items: documents, loadMore: loadMore });
        });

        return result;
    };

    updateDocument(table: Tables, id: string, fields: any): ResultAsync<string, string> {
        const newDocument = this.firestore.collection(table).doc(id);

        const result = ResultAsync.fromPromise(
            newDocument.update(fields),
            (error) => `Updating item in table ${table} failed with: ${(error as Error).message}`
        );
        return result.map(() => newDocument.id);
    }

    increment(id: string, table: Tables, field: string) {
        const increment = firebase.firestore.FieldValue.increment(1);
        const storyRef = this.firestore.collection(table).doc(id);
        const result = ResultAsync.fromPromise(
            storyRef.update({ [field]: increment }),
            (error) => `Incrementing airshop with id ${id} in table ${table} failed with: ${(error as Error).message}`
        );
        return result.map(() => id);
    }
}

export default new FirebaseFirestore();
export { Tables, WhereFilterOp, FirestoreItem, QueryCondition, QueryResult };
