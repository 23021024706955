class LoggingService {
    error = (errorMessage: string) => {
        console.log(errorMessage);
    };

    log = (message: string) => {
        console.log(message);
    };
}

export default new LoggingService();
