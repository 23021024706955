enum ApiPaths {
    TABLE_PATH = "/table",
    RECORD_PATH = "/records",
}

enum Collections {
    TABLE_COLLECTION = "tables",
    RECORD_COLLECTION = "records",
    ACCOUNT_COLLECTION = "accounts",
    ORDER_COLLECTION = "orders",
}

enum TableStatus {
    INACTIVE = 'INACTIVE',
    PAID = 'PAID',
    FREE = 'FREE',
    PUBLIC = 'PUBLIC'
}

enum PageStates {
    LOADING = 'LOADING',
    READY = 'READY',
    ERROR = 'ERROR'
}

enum OrderStates {
    PROCESSING = 'PROCESSING',
    SUCCEEDED = 'SUCCEEDED',
    PAYMENT_FAILED = 'PAYMENT_FAILED'
}

enum FileStatus {
    PROCESSING = 'PROCESSING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

enum CellTypes {
    STRING = 'STRING',
    PHONE = 'PHONE',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    EMAIL = 'EMAIL',
    LINK = 'LINK',
    IMAGE = 'IMAGE'
}

type PageState = {
    state: PageStates,
    message?: string
}

type HeaderColumn = {
    value: string;
    label: string;
    index: number;
    note?: string;
    type: CellTypes;
    selected: boolean;
    required: boolean;
    visible: boolean;
    price: number;
    datapoints: number;
}

type Table = {
    id: string;
    accountId: string;
    status: TableStatus;
    tableProvider: string;
    website: string;
    contactMail: string;
    exampleRecordIds: string[];
    url: string;
    ctaDetailsPage: string;
    excludeFromSearch: boolean;
    tags: string[];
    createdAt: Date;
    updatedAt: Date;
    live: boolean;
    headerImage: string;
    title: string;
    subtitle: string;
    description: string;
    minOrderAmount: number;
    minPrice: number;
    currency: string;
    tableHeader: HeaderColumn[];
    totalDataPoints: number;
    downloads: number;
    ownerStripeAccountId: string | null;
    fileRef: string;
}

type Record = {
    id: string;
    lastUpdated: Date;
    tableId: string;
    data: { [key: string]: string }[];
}

enum VendorTypes {
    PRIVATE = 'PRIVATE',
    COMPANY = 'COMPANY'
}

type PrivateVendor = {
    type: VendorTypes.PRIVATE;
    name: string;
    secondaryName: string;
    website?: string;
    country?: string;
    stripeAccountId?: string;
}

type CompanyVendor = {
    type: VendorTypes.COMPANY;
    name: string;
    website?: string;
    country?: string;
    stripeAccountId?: string;
}

enum AccountStatus {
    TABLESTOCK_ACC_CEATED = 'TABLESTOCK_ACC_CEATED',
    STRIPE_ACC_CREATED = 'STRIPE_ACC_CREATED',
    STRIPE_ACC_CONFIRMED = 'STRIPE_ACC_CONFIRMED',
}

type Account = {
    id: string;
    state: AccountStatus;
    avatarUrl: string;
    vendor?: PrivateVendor | CompanyVendor;
    apiId?: string;
}

// type Api = {
//     id: string;
//     apiKey: string;
//     apiSecret: string;
// }

type File = {
    bucket: string;
    ref: string;
    status: FileStatus;
    statusMessage?: string;
    downloads: number;
}

type Order = {
    id: string;
    status: OrderStates;
    statusDetails?: any;
    accountId: string;
    tableId: string;
    datapoints: number;
    email: string;
    tableName: string;
    tableHeader: HeaderColumn[];
    totalPrice: number;
    file: File;
    createdAt: Date;
    expirationDate?: Date;
    updatedAt: Date;
}

export { ApiPaths, Collections, Account, AccountStatus, PageState, PageStates, VendorTypes, CellTypes, Order, File, FileStatus, OrderStates, CompanyVendor, PrivateVendor, TableStatus, HeaderColumn, Table, Record };
