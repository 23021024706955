import { render, staticRenderFns } from "./SearchResultItem.vue?vue&type=template&id=523424cd&"
import script from "./SearchResultItem.vue?vue&type=script&lang=ts&"
export * from "./SearchResultItem.vue?vue&type=script&lang=ts&"
import style0 from "./SearchResultItem.vue?vue&type=style&index=0&id=523424cd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports