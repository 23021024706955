import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

// Sate
import state from './root.state';

// Modules
import marketplace from './modules/marketplace/index';
import dashboard from './modules/dashboard/index';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    marketplace,
    dashboard
  }
});
