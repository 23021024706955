import { ResultAsync } from 'neverthrow';
import functions from '../firebase/functions';
import firestore, { QueryCondition, Tables } from '../firebase/firestore';

// Models
import { HeaderColumn, Order, Table } from "@/../../lib";
import loggingService from './logging.service';

class OrderService {
    newPaymentIntent = async (table: Table, datapoints: number): Promise<ResultAsync<number, string>> => {
        const onNewPaymentIntent = functions.httpsCallable('onNewPaymentIntent');
        return ResultAsync.fromPromise(
            onNewPaymentIntent({ tableId: table.id, tableHeader: table.tableHeader, datapoints: datapoints }),
            (error) => `onNewPaymentIntent failed with: ${(error as Error).message}`
        );
    }

    newOrder = async (orderId: string, table: Table, datapoints: number, tableHeader: HeaderColumn[], email: string): Promise<ResultAsync<number, string>> => {
        const onCreateOrder = functions.httpsCallable('onCreateOrder');
        return ResultAsync.fromPromise(
            onCreateOrder({
                tableId: table.id,
                accountId: table.accountId,
                orderId: orderId,
                datapoints: datapoints,
                tableHeader: tableHeader,
                email: email
            }),
            (error) => `onCreateOrder failed with: ${(error as Error).message}`
        );
    }

    get = async (orderId: string): Promise<ResultAsync<Order, string>> => {
        return firestore.getDocument<Order>(orderId, Tables.orders);
    };

    orderListener = (
        orderId: string,
        onUpdate: (orders: Order[]) => any,
        onError: (error: string) => any
    ): void => {
        const conditions: QueryCondition[] = [
            {
                field: 'id',
                operation: '==',
                value: orderId
            }
        ];

        const newOnUpdate = (order: Order[]) => {
            onUpdate(order);
        };

        const newOnError = (error: Error) => {
            loggingService.error(`listenToAirshopChanges: ${error.message}`);
            return onError(error.message);
        };

        firestore.listenToQueryWhere<Order>(
            Tables.orders,
            conditions,
            newOnUpdate,
            newOnError
        );
    }

    sendOrderEmail = async (orderId: string): Promise<ResultAsync<void, string>> => {
        const onSendOrderEmail = functions.httpsCallable('onSendOrderEmail');
        return ResultAsync.fromPromise(
            onSendOrderEmail({ orderId: orderId }),
            (error) => `onSendOrderEmail failed with: ${(error as Error).message}`
        );
    }
}

export default new OrderService();
