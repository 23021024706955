import { State } from "./state";
import { Table, Record } from "@/../../lib";

export const SET_CURRENT_TABLE = (state: State, currentTable: Table | null): void => {
    // Sort header cells by index
    currentTable?.tableHeader.sort((a, b) => a.index - b.index);
    state.currentTable = currentTable;
}

export const SET_HOME_TABLES = (state: State, homeTables: Table[] | null): void => {
    state.homeTables = homeTables;
}

export const SET_TABLE_CELL_SELECTED = (state: State, cellValue: string): void => {
    state.currentTable?.tableHeader.forEach(header => {
        if (header.value === cellValue) {
            header.selected = !header.selected;
        }
    });
}

export const SET_EXAMPLE_RECORDS = (state: State, exampleRecords: Record[]): void => {
    state.exampleRecords = exampleRecords;
}
