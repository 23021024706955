import { Account } from "@/../../lib";

interface State {
    account: Account | null;
}

export const state: State = {
    account: null,
};

export default state;
export { State };
